<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>
              {{ fromMyConsole ? "我的主控台" : "獎勵應用中心" }}
            </b-breadcrumb-item>
            <b-breadcrumb-item active>{{ title }}</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-4 mb-2 mb-xl-0 font-weight-bold">
          {{ title }}
        </h4>
        <div
          class="
            col-12 col-xl-8
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
            mt-2 mt-xl-0
          "
        >
          <div class="flex-shrink-0">
            <b-button
              v-if="checkPermission([consts.REDEEM_CODE_USED_LIST_VIEW])"
              class="mb-2 mb-xl-0 mr-2 ml-n3 ml-xl-0"
              variant="link"
              :to="{
                name: 'BelongedRedeemCodeList',
              }"
            >
              <i class="fa fa-folder-open"></i>優惠券使用紀錄
            </b-button>
            <b-button
              v-if="
                checkPermission([consts.REDEEM_CODE_LIST_CREATE]) ||
                fromMyConsole
              "
              class="mb-2 mb-xl-0 mr-2"
              variant="primary"
              @click="handleCreate"
            >
              <i class="fa fa-plus"></i>新增優惠券
            </b-button>
          </div>

          <b-form-select
            v-if="false"
            class="mr-2"
            v-model="query.create_type"
            :options="createTypes"
            @change="handlePageReset"
          ></b-form-select>
          <b-form-select
            v-model="query.merchant_id"
            :options="merchants"
            @change="handlePageReset"
          ></b-form-select>
          <b-input-group class="ml-0 ml-xl-4">
            <b-form-input
              v-model="query.keyword"
              v-on:keyup.enter="handlePageReset"
              placeholder="請輸入優惠券名稱"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="handlePageReset">
                <i class="fa fa-search"></i>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <b-table
        striped
        hover
        responsive
        :items="redeemCodeBundles"
        :fields="fields"
        :busy="isLoading"
      >
        <template #cell(img_url)="data">
          <b-img-lazy
            v-if="data.item.img_url"
            class="rounded-0"
            style="width: 100px; height: 50px"
            :src="data.item.img_url"
            alt="圖片"
          />
        </template>
        <template #cell(start_at_and_end_at)="data">
          <div class="mb-1">{{ formatDate(data.item.start_at) }} ~</div>
          <div>{{ formatDate(data.item.end_at) }}</div>
        </template>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-button
            class="ml-2"
            variant="inverse-dark"
            @click="showAssignUrlModal(data.item)"
          >
            <i class="fa fa-link ml-2"></i>
          </b-button>
          <b-button
            class="ml-2"
            variant="inverse-info"
            v-if="
              checkPermission([consts.REDEEM_CODE_LIST_MODIFY]) || fromMyConsole
            "
            :to="{
              name: fromMyConsole
                ? 'MyConsoleRedeemCodeBundleRedeemCodes'
                : 'RedeemCodeBundleRedeemCodes',
              params: {
                redeemCodeBundleId: data.item.id,
              },
            }"
          >
            <span class="mdi mdi-settings"></span> 序號管理
          </b-button>

          <b-button
            class="ml-2"
            variant="inverse-primary"
            :to="{
              name: fromMyConsole
                ? 'MyConsoleRedeemCodeBundleDetail'
                : 'RedeemCodeBundleDetail',
              params: {
                redeemCodeBundleId: data.item.id,
              },
            }"
          >
            <span class="mdi mdi-eye"></span> 查看
          </b-button>

          <b-button
            v-if="
              checkPermission([consts.REDEEM_CODE_LIST_MODIFY]) || fromMyConsole
            "
            class="ml-2"
            variant="inverse-warning"
            :to="{
              name: fromMyConsole
                ? 'MyConsoleRedeemCodeBundleEdit'
                : 'RedeemCodeBundleEdit',
              params: {
                redeemCodeBundleId: data.item.id,
              },
            }"
          >
            <span class="mdi mdi-lead-pencil"></span> 編輯
          </b-button>
          <b-button
            v-if="
              checkPermission([consts.REDEEM_CODE_LIST_DELETE]) || fromMyConsole
            "
            class="ml-2"
            variant="inverse-danger"
            @click="handleDelete(data.item)"
          >
            <span class="mdi mdi-delete"></span> 刪除
          </b-button>
        </template>
      </b-table>
      <CustomPagination
        :currentPage="query.page"
        :totalRows="total"
        :perPage="query.per_page"
        :perPageOptions="perPageOption"
        @page-change="handlePageChange"
        @per-page-change="handlePerPageChange"
      />
    </b-card>

    <b-modal
      id="assign-url-modal"
      footer-class="d-flex justify-content-center"
      hide-header
    >
      <b-textarea
        id="assign-url"
        v-b-tooltip.click="'已複製'"
        v-model="assignUrl"
        @click="copyAssignUrl"
        class="mt-2"
      ></b-textarea>
      <template #modal-footer>
        <b-button
          size="lg"
          variant="danger"
          @click="$bvModal.hide('assign-url-modal')"
          >關閉</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import merchantApi from "../../../apis/merchant";
import redeemCodeBundleApi from "../../../apis/redeemCodeBundle";
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";
import { format } from "date-fns";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";
import { paginationMixin } from "@/mixins/pagination";

export default {
  components: {
    CustomPagination,
  },
  mixins: [paginationMixin],
  data() {
    const fromMyConsole = this.$route.name === "MyConsoleRedeemCodeBundleList";
    return {
      consts,
      isLoading: false,
      search: "",
      merchants: [{ value: null, text: "請選擇" }],
      createTypes: [
        { value: null, text: "全部" },
        { value: "0", text: "組織優惠券" },
        { value: "1", text: "員工優惠券" },
      ],
      redeemCodeBundles: [],
      fields: [
        {
          key: "img_url",
          label: "圖片",
        },
        {
          key: "name",
          label: "獎項名稱",
          formatter: (value) =>
            value.length > 20 ? value.substring(0, 20) + "..." : value,
        },
        {
          key: "type",
          label: "獎項種類",
          formatter: (value) => {
            return consts.REDEEM_CODE_BUNDLE_TYPE_TRANSLATE[value];
          },
        },
        {
          key: "start_at_and_end_at",
          label: "開始/結束日",
        },
        {
          key: "redeem_codes_amount",
          label: "總數",
          formatter: (value) => {
            if (value > 9999999) {
              value = 9999999;
              return (
                value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "+"
              );
            } else {
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
          },
        },
        {
          key: "apply_at_amount",
          label: "已領取",
          formatter: (value) => {
            if (value > 9999999) {
              value = 9999999;
              return (
                value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "+"
              );
            } else {
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
          },
        },
        {
          key: "redeem_at_amount",
          label: "已兌換",
          formatter: (value) => {
            if (value > 9999999) {
              value = 9999999;
              return (
                value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "+"
              );
            } else {
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
          },
        },
        {
          key: "actions",
          label: "管理",
        },
      ],
      perPage: 15,
      currentPage: 1,
      total: 0,
      fromMyConsole,
      title: fromMyConsole ? "我的優惠券" : "優惠券管理",
      assignUrl: "",
      initialized: false,
      perPageOption: [
        { text: 10, value: 10 },
        { text: 20, value: 20 },
        { text: 50, value: 50 },
        { text: 100, value: 100 },
      ],
      query: {
        page: 1,
        per_page: 10,
        merchant_id: null,
        create_type: "0",
        keyword: "",
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      defaultMerchantType: (state) => state.defaultMerchantType,
    }),
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
  },
  async mounted() {
    await this.fetchMerchants();
    await this.fetchRedeemCodeBundles();
    this.initialized = true;
  },
  async created() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    $route(to) {
      if (!this.initialized) return;
      const defaultQuery = { merchant_id: null };
      this.query = { ...defaultQuery, ...to.query };
      this.fetchRedeemCodeBundles();
    },
  },
  methods: {
    async fetchMerchants() {
      const { data } = await merchantApi.list();

      this.merchants = [
        { value: null, text: "請選擇" },
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: `${merchant.name} (${merchant.type_name})`,
          };
        }),
      ];

      if (this.$route.query.merchant_id) {
        this.query.merchant_id = this.$route.query.merchant_id;

        var hasMerchant = this.merchants.find((item) => {
          return item.value === this.query.merchant_id;
        });

        if (!hasMerchant) {
          this.$swal.fire({
            type: "warning",
            title: "查無此通路",
            confirmButtonText: "確定",
            confirmButtonColor: "#d33",
            reverseButtons: true,
          });
          return;
        }
      } else {
        const defaultMerchant =
          data.data.find(
            (merchant) => merchant.type === this.defaultMerchantType
          ) ?? data.data.find((merchant) => merchant.type === consts.MERCHANT_TYPES.LINE_TYPE);

        if (defaultMerchant) {
          this.query.merchant_id = defaultMerchant.id;
        } else {
          this.query.merchant_id = data.data[0].id;
        }
      }
    },
    async fetchRedeemCodeBundles() {
      try {
        this.isLoading = true;
        const params = {
          fromMyConsole: this.fromMyConsole,
          ...this.query,
        };
        const { data } = await redeemCodeBundleApi.getRedeemCodeBundles(params);
        this.total = data.meta.total;
        this.perPage = data.meta.per_page;
        this.redeemCodeBundles = data.data;
      } catch (error) {
        console.log("");
      }
      this.isLoading = false;
    },
    showAssignUrlModal(redeemCodeBundle) {
      this.assignUrl = `https://liff.line.me/${this.organization.liff_id}/coupon/auto_apply/${redeemCodeBundle.id}`;
      this.$bvModal.show("assign-url-modal");
    },
    copyAssignUrl() {
      const inputText = document.querySelector("#assign-url");
      inputText.select();
      document.execCommand("copy");
    },
    async handleDelete(redeemCodeBundle) {
      this.$swal
        .fire({
          type: "warning",
          title: "你確定要刪除嗎？",
          html: `
              <div class="my-3">
                <div>優惠券名稱：${redeemCodeBundle.name}</div>
                <div>所有優惠券明細(包含已發送)皆會一併刪除。</div>
              </div>`,
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              this.isLoading = true;
              await redeemCodeBundleApi.deleteRedeemCodeBundle(
                redeemCodeBundle.id
              );
              await this.fetchRedeemCodeBundles();
              this.$swal.fire({
                type: "success",
                text: `成功刪除 ${redeemCodeBundle.name}`,
              });
            } catch (error) {
              console.log(error);
            }
            this.isLoading = false;
          }
        });
    },
    handleCreate() {
      if (this.fromMyConsole) {
        this.$router.push({ name: "MyConsoleRedeemCodeBundleCreate" });
      } else {
        this.$router.push({ name: "RedeemCodeBundleCreate" });
      }
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    formatDate(date) {
      return date ? format(new Date(date), "yyyy-MM-dd") : "無期限";
    },
  },
};
</script>
